import React, { Component, createRef } from 'react'
import { Link } from 'react-router-dom'

import AppInit from '../AppInit'

import NavBar from '../NavBar/NavBar'
import Preloader from '../components/UI/Preloader/Preloader'
import Msg from '../components/UI/Msg/Msg'

import './MyProfile.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class MyProfile extends Component {
    constructor(props) {
        super(props)

        this.msgRef = createRef()

        this.state = {
            isWait: true,
        }
    }

    render() {
        const phones = AppInit.getPhones().map((phone) => phone.number)

        const payment = (
            <div
                className="f-container"
                onTouchStart={() => (window.location.href = '/payment')}
            >
                <div>
                    <FontAwesomeIcon
                        icon="exclamation-triangle"
                        color="tomato"
                    />{' '}
                    Счет. {AppInit.getPendingTransactionAmount()} byn.{' '}
                    {AppInit.getPendingTransactionDescription()}.
                </div>
                <Link to="/payment">
                    <FontAwesomeIcon
                        icon="angle-right"
                        size="2x"
                        color="navy"
                    />
                </Link>
            </div>
        )

        return (
            <div>
                <Msg ref={this.msgRef} />
                {this.state.isWait ? <Preloader /> : null}
                <div className="MyProfile">
                    <div className="main-block">
                        <h1>Мой профиль</h1>
                        <div className="f-container">
                            <div>{AppInit.getUserName()}</div>
                        </div>
                        <div
                            className="f-container"
                            onTouchStart={() => {
                                window.location.href =
                                    AppInit.getPendingTransactionId() > 0
                                        ? '/payment'
                                        : '/phone-list'
                            }}
                        >
                            <div className="phones-container">
                                <div className="phones" id="phones">
                                    {phones.join(', ')}
                                </div>
                            </div>
                            <Link
                                to={
                                    AppInit.getPendingTransactionId() > 0
                                        ? '/payment'
                                        : '/phone-list'
                                }
                            >
                                <FontAwesomeIcon
                                    icon="angle-right"
                                    size="2x"
                                    color="navy"
                                />
                            </Link>
                        </div>
                        <div
                            className="f-container"
                            onTouchStart={() => {
                                window.location.href =
                                    AppInit.getPendingTransactionId() > 0
                                        ? '/payment'
                                        : '/tariff-list'
                            }}
                        >
                            <div>Тариф - {AppInit.getTariffName()}</div>
                            <Link
                                to={
                                    AppInit.getPendingTransactionId() > 0
                                        ? '/payment'
                                        : '/tariff-list'
                                }
                            >
                                <FontAwesomeIcon
                                    icon="angle-right"
                                    size="2x"
                                    color="navy"
                                />
                            </Link>
                        </div>

                        <div
                            className="f-container"
                            onTouchStart={() => {
                                window.location.href = '/my-reviews'
                            }}
                        >
                            <div>Мои отзывы</div>
                            <Link to="/my-reviews">
                                <FontAwesomeIcon
                                    icon="angle-right"
                                    size="2x"
                                    color="navy"
                                />
                            </Link>
                        </div>
                        {AppInit.getPendingTransactionId() > 0 ? payment : null}
                    </div>

                    <div className="footer">
                        <a
                            href="https://www.alodging.by/politika-konfidencialnosti"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Политика конфиденциальности
                        </a>
                        <a
                            href="https://www.alodging.by/dogovor"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Договор
                        </a>
                    </div>
                </div>
                <NavBar />
            </div>
        )
    }

    componentDidMount() {
        AppInit.getMyAccauntInfo()
            .then(() => AppInit.checkPendingTransaction())
            .then(() => {
                this.setState({
                    isWait: false,
                })
            })
            .catch((error) => {
                this.setState({ isWait: false })
                this.msgRef.current.show('error', error.message, () => {
                    this.props.history.push('/login')
                })
            })

        this.movePhones()
    }

    movePhones() {
        let startX = 0
        let startLeft = 0

        let phonesE = document.querySelector('.phones')

        let startMove = function (e, x) {
            startX = x
            let phonesLeft = e.target.getBoundingClientRect().left
            let phonesContainerLeft = e.target.parentElement.getBoundingClientRect()
                .left
            startLeft =
                phonesLeft > 0 ? phonesLeft - phonesContainerLeft : phonesLeft
        }

        let move = function (e, x) {
            if (startX) {
                const shift = x - startX
                const newLeft = startLeft + shift
                if (
                    (shift > 0 && newLeft < 0) ||
                    (shift < 0 &&
                        phonesE.offsetWidth + newLeft >
                            phonesE.parentElement.offsetWidth)
                ) {
                    e.target.style.left = newLeft + 'px'
                }
            }
        }

        let stopMove = function () {
            startX = 0
        }

        phonesE.addEventListener('mousedown', (e) => startMove(e, e.clientX))
        phonesE.addEventListener('mousemove', (e) => move(e, e.clientX))
        phonesE.addEventListener('mouseup', stopMove)
        phonesE.addEventListener('mouseout', stopMove)

        phonesE.addEventListener('touchstart', (e) =>
            startMove(e, e.touches[0].clientX)
        )
        phonesE.addEventListener('touchmove', (e) =>
            move(e, e.touches[0].clientX)
        )
        phonesE.addEventListener('touchend', stopMove)
    }
}

export default MyProfile
