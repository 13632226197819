import React, { useState, useEffect } from 'react'
import CognitoHandle from '../../CognitoAuth/CognitoHandle'
import NavBar from '../../NavBar/NavBar'
import { MyFeedback } from '../../containers/FBSearch/Feedback/Feedback'
import ContainerHead from '../../components/ContainerHead/ContainerHead'
import AppInit from '../../AppInit'

import './MyReviews.css'

function MyReviews(props) {
    const [reviews, setReviews] = useState([])

    useEffect(() => {
        AppInit.fetchCategories()
            .then(() =>
                fetch('https://api.alodging.by/v1/api/mob/feedback', {
                    method: 'GET',
                    headers: {
                        Accept: '*/*',
                        'Content-Type': 'application/json',
                        Authorization: CognitoHandle.tokens.idToken,
                    },
                })
            )
            .then((res) => res.json())
            .then((data) => {
                // console.log(data)
                readData(data)
            })
            .catch((err) => console.log(err.message))
    }, [])

    return (
        <>
            <div className="MyReviews">
                <ContainerHead
                    onClick={() => props.history.goBack()}
                    name="Мои отзывы"
                />
                {reviews.map((item) => {
                    return <MyFeedback feedback={item} key={item.id} />
                })}
            </div>
            <NavBar />
        </>
    )

    function readData(data) {
        let reviews = []
        data.content.forEach((item) => reviews.push(item))
        setReviews(reviews)
    }
}

export default MyReviews
