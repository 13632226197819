import React from 'react'

import Overlay from "../UI/Overlay/Overlay"

import './SelectItem.css'

export default (props) => {
    const box = props.parentElement.getBoundingClientRect()
    const top = box.top + box.height + window.pageYOffset + 'px'

    return (
        <>
            <Overlay color={props.overlayColor} onClick={props.overlayOnClick} />
            <ul className="SelectItem" style={{ top: top }}>
                {props.items.map((item) => {
                    return (
                        <li
                            key={item.id}
                            onClick={() => props.onClick(item)}
                        >
                            {item.name}
                        </li>
                    )
                })}
            </ul>
        </>
    )
}