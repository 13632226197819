import React, { Component, createRef } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import CognitoHandle from './CognitoAuth/CognitoHandle'
import CognitoAuth from './CognitoAuth/CognitoAuth'

import AddReview from './containers/AddReview/AddReview'
import AddReviewSuccess from './components/AddReviewSuccess/AddReviewSuccess'
import MyReviews from './containers/MyReviews/MyReviews'
import FBSearchForm from './containers/FBSearchForm/FBSearchForm'
import FBSearch from './containers/FBSearch/FBSearch'
import MyProfile from './MyProfile/MyProfile'
import PhoneList from './MyProfile/PhoneList/PhoneList'
import Payment from './MyProfile/Payment/Payment'
import TariffList from './MyProfile/TariffList/TariffList'
import AboutPayment from './components/AboutPayment/AboutPayment'

import InitAppScreen from './components/UI/InitAppScreen/InitAppScreen'

import './App.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faCheck,
    faTimes,
    faAngleRight,
    faExclamationTriangle,
    faChevronLeft,
    faStar,
    faMap,
    faThumbsDown,
} from '@fortawesome/free-solid-svg-icons'
import {
    faCircle,
    faDotCircle,
    faStar as farStar,
    faCheckCircle,
} from '@fortawesome/free-regular-svg-icons'

library.add(
    faCheck,
    faTimes,
    faAngleRight,
    faExclamationTriangle,
    faChevronLeft,
    faStar,
    faCircle,
    faDotCircle,
    farStar,
    faCheckCircle,
    faMap,
    faThumbsDown
)

class App extends Component {
    constructor(props) {
        super(props)

        this.msgRef = createRef()

        this.state = {
            init: false,
            initError: false,
        }
    }

    componentDidMount() {
        let self = this

        CognitoHandle.refreshSession(function (err) {
            let initError
            if (err) {
                initError = true
            }
            self.setState({ init: true, initError })
        })
    }

    render() {
        return (
            <div className="App">
                {this.state.init ? (
                    this.state.initError ? (
                        <Switch>
                            <Route path="/login" component={CognitoAuth} />
                            <Route path="/tariff-list" component={TariffList} />
                            <Route
                                path="/about-payment"
                                component={AboutPayment}
                            />
                            <Redirect to="/login" />
                        </Switch>
                    ) : (
                        <Switch>
                            <Route path="/add-review" component={AddReview} />
                            <Route
                                path="/add-review-success"
                                component={AddReviewSuccess}
                            />
                            <Route path="/my-reviews" component={MyReviews} />
                            <Route path="/" exact component={FBSearchForm} />
                            <Route path="/search" component={FBSearch} />
                            <Route path="/login" component={CognitoAuth} />
                            <Route path="/settings" component={MyProfile} />
                            <Route
                                path="/about-payment"
                                component={AboutPayment}
                            />
                            <Route path="/phone-list" component={PhoneList} />
                            <Route path="/payment" component={Payment} />
                            <Route path="/tariff-list" component={TariffList} />
                            <Redirect to="/" />
                        </Switch>
                    )
                ) : (
                    <InitAppScreen />
                )}
            </div>
        )
    }
}

export default App
