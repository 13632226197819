import React from 'react';
import './PhoneView.css';

export default (props) => {
    return (
        <div className="PhoneView" >
            <div className="info">
                {props.phone.number}
            </div>
            <div className="edit" onClick={props.onEdit}></div>
            <div className="del" onClick={props.onDel}></div>
        </div>
    )
}