import React from 'react'
import {Link} from 'react-router-dom'
import NavBar from '../../NavBar/NavBar'
import './AddReviewSuccess.css'

export default props => {
    return (
        <>
            <div className="AddReviewSuccess">
                <h1>Отзыв успешно сохранен!</h1>
                <h2>Спасибо за отзыв!<br/>Мы все делаем общее дело!</h2>
                <Link to="/add-review">Создать новый отзыв</Link>
            </div>
            <NavBar />
        </>
    )
}