import React from 'react'
import './MsgBox.css'

export default (props) => {
    return (
        <>
            <div className="MsgBox-overlay"></div>
            <div className="MsgBox">
                <div className="txt">{props.msgTxt}</div>
                <div className="wrap-buttons">
                    <button
                        className="OK"
                        onClick={props.onClickOk}
                    >
                        {props.okTxt}
                    </button>
                    <button
                        className="cancel"
                        onClick={props.onClickCancel}
                    >
                        {props.cancelTxt}
                    </button>
                </div>
            </div>
        </>
    )
}