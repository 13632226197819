import React from 'react'

import './Overlay.css'

export default props => {
    return (
        <div className="Overlay"
             style={{backgroundColor: props.color}}
             onClick={props.onClick}
        >
        </div>
    )
}