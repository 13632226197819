import React from 'react';
import {NavLink} from 'react-router-dom';
import './NavBar.css';

function NavBar(props) {
    return (
        <div className="NavBar">
            <nav>
                <NavLink className="add-review" to="/add-review"></NavLink>
                <NavLink className="search" to="/" exact></NavLink>
                <NavLink className="settings" to="/settings"></NavLink>
            </nav>
        </div>
    );
}

export default NavBar;