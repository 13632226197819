import React from 'react'
import './TextField.css'

const TextField = React.forwardRef((props, ref) => {

    const inputType = props.type || 'text'

    const cls = ['TextField']

    if (inputType === 'textarea')
        cls.push('textarea')

    return (
        <div className={cls.join(' ')}>
            <fieldset ref={ref}>
                <legend>{props.label}</legend>
                <div>
                    <div className="leadingIcon">
                        {props.leadingIcon}
                    </div>
                    {
                        inputType === 'textarea'
                            ? <textarea
                                name={props.name}
                                value={props.value}
                                onChange={props.onChange}
                            />
                            : <input
                                type={inputType}
                                name={props.name}
                                value={props.value}
                                placeholder={props.placeholder}
                                onChange={props.onChange}
                                readOnly={props.readOnly}
                                autoComplete="off"
                            />
                    }
                    <div className="trailingIcon">
                        {props.trailingIcon}
                    </div>
                </div>
            </fieldset>
            <div className="alert">{props.alert}</div>
        </div>
    )
})

export default TextField