import CognitoHandle from './CognitoAuth/CognitoHandle'

function AppInit() {
    const scope = 'work'

    const endPoints = {
        sandBox: 'http://ec2-35-176-152-8.eu-west-2.compute.amazonaws.com:8080',
        work: 'https://api.alodging.by/v1',
    }

    const fetchHeaders = {
        Accept: '*/*',
        'Content-Type': 'application/json',
    }

    let categories = []

    let userInfo = {
        admin: false,
        balance: 0,
        expireDate: '',
        name: '',
        phones: [],
        tariffName: '',
    }

    let pendingTransaction = {
        id: -1,
        amount: 0,
        description: '',
    }

    let myTariff = {
        id: 0,
        expireDate: null,
    }

    let tariffList = []

    this.getAuthHeader = (scope) => {
        if (scope === 'sandBox')
            return { phoneNumber: CognitoHandle.authPhoneNumber }
        if (scope === 'work')
            return { Authorization: CognitoHandle.tokens.idToken }
        return {}
    }

    this.getMyAccauntInfo = () =>
        new Promise((resolve, reject) => {
            let status

            fetch(endPoints[scope] + '/api/mob/account', {
                method: 'GET',
                headers: Object.assign(fetchHeaders, this.getAuthHeader(scope)),
            })
                .then((response) => {
                    status = response.status
                    return response.json()
                })
                .then((data) => {
                    if (status === 200) {
                        userInfo = data
                        resolve()
                    } else {
                        console.log(data)
                        reject(data)
                    }
                })
                .catch((error) => reject(error.message))
        })

    this.getUserName = function () {
        return userInfo.name
    }

    this.getTariffName = function () {
        return userInfo.tariffName
    }

    this.getExpireDate = function () {
        return userInfo.expireDate
    }

    this.getPhones = function () {
        let phones = []
        userInfo.phones.forEach(function (item) {
            let phone = { ...item }
            phones.push(phone)
        })
        return phones
    }

    this.savePhones = (phones) =>
        new Promise((resolve, reject) => {
            let status

            fetch(endPoints[scope] + '/api/mob/account/update/phonelist', {
                method: 'POST',
                headers: Object.assign(fetchHeaders, this.getAuthHeader(scope)),
                body: JSON.stringify(phones),
            })
                .then((response) => {
                    status = response.status
                    return response.json()
                })
                .then((data) => {
                    let needPayment = false
                    if (status === 200) {
                        needPayment = data.needPayment
                    } else {
                        reject(data.message)
                    }
                    resolve(needPayment)
                })
                .catch((error) => reject(error.message))
        })

    this.checkPendingTransaction = () =>
        new Promise((resolve, reject) => {
            let status

            fetch(endPoints[scope] + '/api/pending/transaction', {
                method: 'GET',
                headers: Object.assign(fetchHeaders, this.getAuthHeader(scope)),
            })
                .then((response) => {
                    status = response.status
                    return response.json()
                })
                .then((data) => {
                    if (status === 200) {
                        pendingTransaction.id = data.trxId
                        pendingTransaction.amount = data.amount
                        pendingTransaction.description = data.description
                    } else {
                        console.log(data)
                    }
                    resolve()
                })
                .catch((error) => reject(error.message))
        })

    this.getPendingTransactionId = () => pendingTransaction.id

    this.getPendingTransactionAmount = () => pendingTransaction.amount

    this.getPendingTransactionDescription = () => pendingTransaction.description

    this.preparePayment = (type) =>
        new Promise((resolve, reject) => {
            let status

            fetch(
                endPoints[scope] +
                    '/api/payment/webpay/invoice?pendingTransactionId=' +
                    pendingTransaction.id +
                    '&type=' +
                    type,
                {
                    method: 'GET',
                    headers: Object.assign(
                        fetchHeaders,
                        this.getAuthHeader(scope)
                    ),
                }
            )
                .then((response) => {
                    status = response.status
                    return response.json()
                })
                .then((data) => {
                    if (status === 200) {
                        resolve(data)
                    } else {
                        console.log(data)
                        reject(data)
                    }
                })
                .catch((error) => reject(error.message))
        })

    this.denyPendingTransaction = () =>
        new Promise((resolve, reject) => {
            fetch(
                endPoints[scope] +
                    '/api/pending/transaction?transactionId=' +
                    pendingTransaction.id,
                {
                    method: 'DELETE',
                    headers: Object.assign(
                        fetchHeaders,
                        this.getAuthHeader(scope)
                    ),
                }
            )
                .then((response) => {
                    if (response.status === 200) {
                        resolve()
                    } else {
                        reject(
                            'Ошибка удаления счета id=' + pendingTransaction.id
                        )
                    }
                })
                .catch((error) => reject(error.message))
        })

    this.fetchTariffList = () =>
        new Promise((resolve, reject) => {
            fetch(endPoints[scope] + '/api/mob/tariff', {
                method: 'GET',
                headers: fetchHeaders,
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    tariffList = data
                    resolve()
                })
                .catch((error) => reject(error.message))
        })

    this.getTariffList = () => tariffList

    this.fetchMyTariff = () =>
        new Promise((resolve, reject) => {
            let status

            if (scope === 'work' && !CognitoHandle.tokens.idToken) {
                reject({ message: 'User is not login. ID token = 0' })
            } else {
                fetch(endPoints[scope] + '/api/mob/account/tariff', {
                    method: 'GET',
                    headers: Object.assign(
                        fetchHeaders,
                        this.getAuthHeader(scope)
                    ),
                })
                    .then((response) => {
                        status = response.status
                        return response.json()
                    })
                    .then((data) => {
                        if (status === 200) {
                            myTariff.id = data.id
                            myTariff.expireDate = data.expireDate
                        }
                        resolve()
                    })
                    .catch((error) => reject(error.message))
            }
        })

    this.getMyTariff = () => myTariff

    this.getProlongatePeriodStr = (monthCount) => {
        if (monthCount === 1) return '1 месяц'

        if (monthCount > 1 && monthCount < 5) return monthCount + ' месяца'

        if (monthCount > 4) return monthCount + ' месяцев'

        return undefined
    }

    this.prolongateTariff = (monthCount) =>
        new Promise((resolve, reject) => {
            let status

            fetch(endPoints[scope] + '/api/mob/account/update/prolongate', {
                method: 'POST',
                headers: Object.assign(fetchHeaders, this.getAuthHeader(scope)),
                body: JSON.stringify({ months: monthCount }),
            })
                .then((response) => {
                    status = response.status
                    return response.json()
                })
                .then((data) => {
                    let needPayment = false
                    if (status === 200) {
                        needPayment = data.needPayment
                    } else {
                        reject(data.message)
                    }
                    resolve(needPayment)
                })
                .catch((error) => reject(error.message))
        })

    this.updateTariff = (id) =>
        new Promise((resolve, reject) => {
            let status

            fetch(endPoints[scope] + '/api/mob/account/update/tariff', {
                method: 'POST',
                headers: Object.assign(fetchHeaders, this.getAuthHeader(scope)),
                body: JSON.stringify({ id: id }),
            })
                .then((response) => {
                    status = response.status
                    return response.json()
                })
                .then((data) => {
                    let needPayment = false
                    if (status === 200) {
                        needPayment = data.needPayment
                    } else {
                        reject(data.message)
                    }
                    resolve(needPayment)
                })
                .catch((error) => reject(error.message))
        })

    this.fetchCategories = () =>
        new Promise((resolve, reject) => {
            let status

            fetch(endPoints[scope] + '/api/mob/init/feedback/category', {
                method: 'GET',
                headers: Object.assign(fetchHeaders, this.getAuthHeader(scope)),
            })
                .then((response) => {
                    status = response.status
                    return response.json()
                })
                .then((data) => {
                    if (status === 200) {
                        categories = data
                    } else {
                        reject(data)
                    }
                    resolve()
                })
                .catch((error) => reject(error))
        })

    this.getCategories = () => categories

    this.getCategoryName = (id) => {
        const item = categories.find((item) => item.id === id) || {}
        return item.name
    }
}

export default new AppInit()
