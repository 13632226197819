import React from 'react'
import { Link } from 'react-router-dom'
import './PaymentLogo.css'

export default (props) => {
    return (
        <div className="PaymentLogo">
            {
                props.tariff
                ? <Link to="/tariff-list">Тарифы</Link>
                : null
            }
            {
                props.about
                    ? <Link to="/about-payment">Онлайн оплата услуг</Link>
                    : null
            }
            <div></div>
        </div>
    )
}