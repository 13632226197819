import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './VisitType.css'

const visitTypeStr = {
    DENIED: {
        name: 'Гостю отказано',
        description: 'Мною отказано в заселении при встрече или по телефону. Вызывают сомнения о добросовестности целей аренды (поведение, манеры, возраст, подозрительные)'
    },
    NOT_COME: {
        name: 'Незаезд без причины',
        description: 'Бронируют "про запас", не заезжают не предупредив, подтверждают заказ до последнего момента, а потом недоступны'
    },
    NOT_COME_WITH_REASON: {
        name: 'Незаезд по причине',
        description: 'Заранее предупреждают об отмене брони, уважительные обстоятельства'
    },
    LIVED: {
        name: 'Заселился',
        description: ''
    }
}

export default props => {
    let cls = ['VisitType']

    props.type === props.value && cls.push('valid')

    const icon = props.type === props.value ? ['far', 'dot-circle'] : ['far', 'circle']

    return (
        <div className={cls.join(' ')} >
            <FontAwesomeIcon icon={icon} size="lg" onClick={() => props.onClick(props.type)}/>
            <span>{visitTypeStr[props.type].name}</span>

            {
                props.type === props.value
                    ? <div>{visitTypeStr[props.type].description}</div>
                    : null
            }
        </div>
    )
}