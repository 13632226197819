import React, { Component, createRef } from 'react';

import CognitoHandle from './CognitoHandle';

import Preloader from '../components/UI/Preloader/Preloader';
import Msg from '../components/UI/Msg/Msg';

import logo from './logo.svg';
import './CognitoAuth.css';

import PaymentLogo from '../components/UI/PaymentLogo/PaymentLogo';

class CognitoAuth extends Component {
    constructor(props) {
        super(props);

        this.msgRef = createRef();

        this.askCustomChallenge = false;

        this.state = {
            waitResponse: false,
            getSMSButtonDisable: true,
            enterButtonDisable: true
        }
    }

    authHandle() {
        const self = this;

        const phoneNumber = document.querySelector('input[name="phoneNumber"]').value;
        const phoneNumberFormatted = phoneNumber.split('')
            .filter(char => char !== ' ' && (char === '+' || Number.isInteger(-char)))
            .join('');

        this.setState({ waitResponse: true });

        CognitoHandle.initiateAuth(phoneNumberFormatted, (err) => {
            self.setState({ waitResponse: false });
            if (err) {
                let msgTxt = '';
                switch (err.code) {
                    // case 'UserLambdaValidationException':
                    case 'UserNotFoundException':
                        msgTxt = <div>Номер {phoneNumber} не зарегистрирован в базе. Для подключения напишите сообщение администратору в Viber<br />+375 (33) 371-11-10</div>;
                        break;
                    case 'InvalidParameterException':
                        msgTxt = <div>Внесите телефон в формате <br />+XXX XX XXXXXXX</div>;
                        break;
                    default:
                        msgTxt = err.message;
                }
                self.msgRef.current.show('error', msgTxt);
            } else {
                self.askCustomChallenge = true;
                document.querySelector('input[name="smsCode"]').focus();
            }
        })
    }

    askCustomChallengeHandle() {
        const self = this;

        const challengeAnswer = document.querySelector('input[name="smsCode"]').value;

        this.setState({ waitResponse: true });

        CognitoHandle.sendCustomChallengeAnswer(challengeAnswer, (err) => {
            self.setState({ waitResponse: false });
            if (err) {
                let errMessage = '';
                switch (err.code) {
                    case 'PreviousAttemptIsFailedException':
                        errMessage = 'Неверный код из СМС. Осталось попыток ' + err.attempts;
                        break;
                    case 'NotAuthorizedException':
                        errMessage = 'Неверный код из СМС. Нажмите ВЫСЛАТЬ СМС еще раз';
                        break;
                    default:
                        errMessage = 'Неверный код из СМС'
                }
                self.msgRef.current.show('error', errMessage);
            } else {
                window.location.href = '/';
            }
        })
    }

    inputPhoneNumberHandle(e) {
        const prevState = this.state.getSMSButtonDisable;
        let newState = this.state.getSMSButtonDisable;

        newState = e.target.value.length < 7 || e.target.value.length > 17;

        if (prevState !== newState) {
            this.setState({
                getSMSButtonDisable: newState
            })
        }
    }

    inputSmsCodeHandle(e) {
        if (!this.askCustomChallenge) {
            return;
        }

        const prevState = this.state.enterButtonDisable;
        let newState = this.state.enterButtonDisable;

        newState = e.target.value.length < 4 || e.target.value.length > 7;

        if (prevState !== newState) {
            this.setState({
                enterButtonDisable: newState
            })
        }
    }

    render() {
        return (
            <div className="CognitoAuth">
                <div className="main-block">
                    <Msg ref={this.msgRef} />
                    {this.state.waitResponse && <Preloader />}
                    <img className="logo" src={logo} alt="ALodging LOGO"></img>
                    <fieldset>
                        <legend>Телефон</legend>
                        <input type="text"
                            name="phoneNumber"
                            placeholder="введите номер телефона"
                            autoComplete="off"
                            onChange={this.inputPhoneNumberHandle.bind(this)}
                        />
                    </fieldset>
                    <button
                        className="getSMS"
                        onClick={this.authHandle.bind(this)}
                        disabled={this.state.getSMSButtonDisable}>
                        ВЫСЛАТЬ СМС
                </button>
                    <fieldset>
                        <legend>Код подтверждения</legend>
                        <input type="text"
                            name="smsCode"
                            placeholder="введите пароль из смс"
                            autoComplete="off"
                            onChange={this.inputSmsCodeHandle.bind(this)}
                        />
                    </fieldset>
                    <button
                        className="enter"
                        onClick={this.askCustomChallengeHandle.bind(this)}
                        disabled={this.state.enterButtonDisable}>
                        ВОЙТИ
                </button>
                <div className="i-agree">
                    Войдя в приложение, я соглашаюсь на обработку персональных данных на условиях <a href="https://www.alodging.by/politika-konfidencialnosti" target="_blank" rel="noopener noreferrer">Политики конфиденциальности</a> и принимаю <a href="https://www.alodging.by/dogovor" target="_blank" rel="noopener noreferrer">Договор</a>.
                </div>
                </div>
                <div className="foot-block">
                    <div className="company-info">
                        <div>
                            <div>
                                Услуги оказывает ООО «Элоджин»
                            </div>
                            <div>
                                Республика Беларусь, 220040, г.Минск, ул. Некрасова, 88, помещение 1-5
                            </div>
                        </div>
                        <div>
                            <div>
                                УНП 193244728
                            </div>
                            <div>
                                Тел. +375 (33) 371-11-10
                            </div>
                            <div>
                                Режим работы: ПН - ПТ с 9:00 до 18:00
                            </div>
                        </div>
                    </div>
                    <PaymentLogo tariff about />
                </div>
            </div>
        )
    }
}

export default CognitoAuth;