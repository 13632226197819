import React from 'react'
import './Feedback.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AppInit from '../../../AppInit'

// этот вид используется в результатах поиска
export function Feedback({ feedback }) {
    return (
        <div className="Feedback">
            <div className="author-name">
                <div>{feedback.addDate}</div>
                <div>{feedback.authorName}</div>
            </div>
            <FeedbackTable
                rating={feedback.rating}
                city={feedback.city}
                categories={feedback.categories}
            />
            <div className="comment">{feedback.feedback}</div>
        </div>
    )
}

// этот вид используется для своих отзывов
export function MyFeedback({ feedback }) {
    return (
        <div className="Feedback">
            <div className="author-name">
                <div>{feedback.addDate}</div>
                <div>{feedback.guestFio}</div>
            </div>
            <div className="phone-list">
                {feedback.phoneNumbers.map((item, i) => (
                    <span key={i}>{item}</span>
                ))}
            </div>
            <FeedbackTable
                rating={feedback.rating}
                city={feedback.city}
                categories={feedback.categories}
            />
            <div className="comment">{feedback.feedback}</div>
        </div>
    )
}

export function FeedbackTable({ rating, city, categories }) {
    const ratingPrescriptionValues = [
        'Нет оценки',
        'Не рекомендую',
        'Терпимо',
        'Рекомендую',
    ]
    let ratingPrescription

    switch (rating) {
        case 1:
        case 2:
            ratingPrescription = ratingPrescriptionValues[1]
            break
        case 3:
            ratingPrescription = ratingPrescriptionValues[2]
            break
        case 4:
        case 5:
            ratingPrescription = ratingPrescriptionValues[3]
            break
        default:
            ratingPrescription = ratingPrescriptionValues[0]
    }

    return (
        <div className="opt">
            <div className="rating">
                <div className="rating-value">
                    <FontAwesomeIcon icon="star" />
                    <span>{rating}</span>
                </div>
                <div>{ratingPrescription}</div>
            </div>
            <div className="city">
                <FontAwesomeIcon icon="map" />
                <div>{city}</div>
            </div>
            <div className="categories">
                <FontAwesomeIcon icon="thumbs-down" />
                {categories.map((id) => (
                    <div className="category-name" key={id}>
                        {AppInit.getCategoryName(id)}
                    </div>
                ))}
            </div>
        </div>
    )
}
