import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import './SetCategory.css'

export default props => {
    return (
        <div className="SetCategory">
            {
                props.categoriesList.map(category => makeCategory(category, props.categories, props.onClick))
            }
        </div>
    )
}

function makeCategory(category, categories, onClick) {
    const icon = categories.includes(category.id) ? ['far', 'check-circle'] : ['far', 'circle']

    let cls = ['category']

    categories.includes(category.id) && cls.push('active')

    return (
        <div className={cls.join(' ')} key={category.id}>
            <div className="icon">
                <FontAwesomeIcon icon={icon} size="lg" onClick={() => onClick(category.id)}/>
            </div>
            <div>
                <strong>{category.name}</strong>
                <div>{category.description}</div>
            </div>
        </div>
    )
}