import React, {Component, createRef} from 'react'
import CognitoHandle from '../../CognitoAuth/CognitoHandle'
import AppInit from '../../AppInit'

import TextField from '../../components/UI/TextField/TextField'
import NavBar from '../../NavBar/NavBar'
import SelectItem from '../../components/SelectItem/SelectItem'
import ResultVisitType from './ResultVisitType/ResultVisitType'
import SetRating from './SetRating/SetRating'
import SetCategory from './SetCategory/SetCategory'

import Preloader from '../../components/UI/Preloader/Preloader'
import Msg from '../../components/UI/Msg/Msg'

import './AddReview.css'

class AddReview extends Component {
    constructor(props) {
        super(props)

        this.textFieldHandle = this.textFieldHandle.bind(this)
        this.selectCity = this.selectCity.bind(this)
        this.checkCategory = this.checkCategory.bind(this)
        this.add = this.add.bind(this)

        this.phoneNumberRef = createRef()
        this.birthdayRef = createRef()
        this.cityFieldRef = createRef()
        this.ratingRef = createRef()
        this.msgRef = createRef()

        this.cityList = []

        this.state = {
            isWait: false,
            phoneNumber: '',
            phoneNumberAlert: '',
            fio: '',
            birthday: '',
            birthdayAlert: '',
            city: '',
            cityList: [],
            cityId: 0,
            cityAlert: '',
            orgName: '',
            unp: '',
            resultVisitType: 'LIVED',
            rating: 0,
            ratingAlert: false,
            categoriesList: [],
            categories: [],
            feedback: ''
        }
    }


    render() {

        return (
            <>
                {this.state.isWait ? <Preloader/> : null}

                <Msg ref={this.msgRef}/>

                <div className="AddReview">
                    <h1>Добавить отзыв</h1>
                    <p>Впишите всю имеющуюся информацию о госте подробнее</p>

                    <TextField
                        ref={this.phoneNumberRef}
                        label="Телефон"
                        name="phoneNumber"
                        value={this.state.phoneNumber}
                        placeholder="Формат +375291112233"
                        onChange={this.textFieldHandle}
                        alert={this.state.phoneNumberAlert}
                    />

                    <TextField
                        label="ФИО"
                        name="fio"
                        value={this.state.fio}
                        onChange={this.textFieldHandle}
                    />

                    <TextField
                        ref={this.birthdayRef}
                        label="Дата рождения"
                        name="birthday"
                        value={this.state.birthday}
                        placeholder="ДД.ММ.ГГГГ"
                        onChange={this.textFieldHandle}
                        alert={this.state.birthdayAlert}
                    />

                    <TextField
                        ref={this.cityFieldRef}
                        label="Город сдачи жилья"
                        name="city"
                        value={this.state.city}
                        onChange={this.textFieldHandle}
                        alert={this.state.cityAlert}
                    />

                    {
                        this.state.cityList.length
                            ? <SelectItem items={this.state.cityList} onClick={this.selectCity}
                                          parentElement={this.cityFieldRef.current}/>
                            : null
                    }

                    <TextField
                        label="Название организации"
                        name="orgName"
                        value={this.state.orgName}
                        onChange={this.textFieldHandle}
                    />

                    <TextField
                        label="УПН организации"
                        name="unp"
                        value={this.state.unp}
                        onChange={this.textFieldHandle}
                    />

                    <ResultVisitType
                        value={this.state.resultVisitType}
                        onClick={value => this.setState({
                            resultVisitType: value,
                            rating: 0,
                            categories: []
                        })}
                    />

                    {
                        this.state.resultVisitType === 'LIVED'
                            ? <>
                                <SetRating
                                    ref={this.ratingRef}
                                    value={this.state.rating}
                                    alert={this.state.ratingAlert}
                                    onClick={rating => this.setState({rating: rating, ratingAlert: false})}
                                />
                                <SetCategory
                                    categoriesList={this.state.categoriesList}
                                    categories={this.state.categories}
                                    onClick={this.checkCategory}
                                />
                            </>
                            : null
                    }

                    <TextField
                        type="textarea"
                        label="Комментарий"
                        name="feedback"
                        value={this.state.feedback}
                        onChange={this.textFieldHandle}
                    />
                    <button onClick={this.add}>ДОБАВИТЬ ОТЗЫВ</button>
                </div>
                <NavBar/>
            </>
        )
    }

    componentDidMount() {
        this.setState({isWait: true})
        AppInit.fetchCategories()
            .then(() => {
                this.setState({
                    isWait: false,
                    categoriesList: AppInit.getCategories()
                })
            })
            .catch(error => {
                this.setState({isWait: false})
                this.msgRef.current.show('error', error.message, () => {
                    this.props.history.push('/login')
                })
            })
    }

    textFieldHandle(e) {
        const name = e.target.name

        switch (name) {
            case 'phoneNumber':
                const phoneNumber = e.target.value.split('')
                    .filter(char => char !== ' ' && (char === '+' || Number.isInteger(-char)))
                    .join('')
                this.setState({phoneNumber, phoneNumberAlert: ''})
                break
            case 'city':
                let city = e.target.value
                let cityList = []
                let cityId = 0

                if (city.length) {
                    city = city[0].toUpperCase() + city.slice(1)

                    this.fetchCities(city)
                        .then(cities => {
                                if (cities.length === 1 && city === cities[0].name) {
                                    cityId = cities[0].id
                                } else {
                                    this.cityList = cities
                                    cityList = cities.map(city => {
                                        return {name: `${city.name} (${city.region})`, id: city.id}
                                    })
                                }
                            })
                        .finally(() => this.setState({ city, cityList, cityId, cityAlert: '' }))
                } else {
                    this.setState({city, cityList, cityId, cityAlert: ''})
                }
                break
            case 'birthday':
                const userType = e.target.value.split('')
                    .filter(char => char !== ' ' && (char === '.' || Number.isInteger(-char)))
                    .slice(0, 10)

                const birthday = userType.map((item, i) => {
                    if (i === 2 || i === 5) {
                        if (item !== '.')
                            return `.${item}`
                    } else {
                        if (item === '.')
                            return ''
                    }
                    return item
                }).join('')

                this.setState({birthday, birthdayAlert: ''})
                break
            default:
                this.setState({[name]: e.target.value})
        }
    }

    fetchCities = (city) => new Promise((resolve, reject) => {
        fetch('https://api.alodging.by/v1/api/mob/autocomplete/city', {
            method: 'POST',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({text: city})
        }).then(response => response.json())
            .then(data => resolve(data))
            .catch(error => reject(error));
    })

    selectCity(item) {
        const city = this.cityList.filter(city => city.id === item.id)[0].name
        const cityList = []
        const cityId = item.id

        this.setState({city, cityList, cityId})
    }

    checkCategory(id) {
        let categories = this.state.categories
        if (categories.includes(id)) {
            categories = categories.filter(category => category !== id)
        } else {
            categories.push(id)
        }
        this.setState({categories})
    }

    add() {

        if (!this.state.phoneNumber.length) {
            this.phoneNumberRef.current.scrollIntoView()
            this.phoneNumberRef.current.querySelector('input').focus()
            this.setState({phoneNumberAlert: 'Необходимо внести номер телефона'})
            return
        }

        if (this.state.birthday && !this.state.birthday.match(/(0[1-9]|[12][0-9]|3[01]).(0[1-9]|1[012]).(19|20)\d\d/)) {
            this.birthdayRef.current.scrollIntoView()
            this.birthdayRef.current.querySelector('input').focus()
            this.setState({birthdayAlert: 'Необходимо внести дату рождения корректно'})
            return
        }

        if (!this.state.cityId) {
            this.cityFieldRef.current.scrollIntoView()
            this.cityFieldRef.current.querySelector('input').focus()
            this.setState({cityAlert: 'Необходимо выбрать город из списка'})
            return
        }

        if (this.state.resultVisitType === 'LIVED' && !this.state.rating) {
            this.ratingRef.current.scrollIntoView()
            this.setState({ratingAlert: true})
            return
        }

        this.setState({isWait: true})

        let status;

        fetch('https://api.alodging.by/v1/api/mob/feedback/add', {
            method: 'POST',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Authorization': CognitoHandle.tokens.idToken
            },
            body: JSON.stringify({
                categories: this.state.categories,
                city: this.state.city,
                cityId: this.state.cityId,
                feedback: this.state.feedback,
                guestFio: this.state.fio,
                birthday: this.state.birthday,
                organizationName: this.state.orgName,
                phoneNumbers: [this.state.phoneNumber],
                rating: this.state.rating,
                resultVisitType: this.state.resultVisitType,
                unp: this.state.unp
            })
        })
            .then(response => {
                status = response.status
                return response.json()
            })
            .then(data => {
                if (status === 200) {
                    this.props.history.push('/add-review-success');
                } else {
                    this.setState({isWait: false})
                    this.msgRef.current.show('error', data.message, () => {
                        this.props.history.push('/login')
                    })
                }
            })
            .catch(error => {
                this.setState({isWait: false})
                this.msgRef.current.show('error', error.message)
            })
    }
}

export default AddReview