import React, { Component, createRef } from 'react'
import ContainerHead from '../../components/ContainerHead/ContainerHead'
import Tariff from './Tariff/Tariff'
import MsgBox from '../../components/UI/MsgBox/MsgBox'
import Msg from '../../components/UI/Msg/Msg'
import Preloader from '../../components/UI/Preloader/Preloader'

import AppInit from '../../AppInit'

import './TariffList.css'

class TariffList extends Component {
    constructor(props) {
        super(props)

        this.chooseTariff = this.chooseTariff.bind(this)
        this.applyTariff = this.applyTariff.bind(this)
        this.closeMsgBox = this.closeMsgBox.bind(this)
        this.prolongateTariff = this.prolongateTariff.bind(this)
        this.applyProlongateTariff = this.applyProlongateTariff.bind(this)

        this.tariffList = AppInit.getTariffList()
        this.myTariff = AppInit.getMyTariff()
        this.msgRef = createRef()

        this.state = {
            isWait: true,
            msgBox: {
                show: false,
                msgTxt: '',
                onClickOk: null
            }
        }
    }

    render() {
        return (
            <div className="TariffList">
                {this.state.isWait ? <Preloader /> : null}

                <Msg ref={this.msgRef} />

                {
                    this.state.msgBox.show ?
                        <MsgBox
                            msgTxt={this.state.msgBox.msgTxt}
                            okTxt="ОК"
                            onClickOk={this.state.msgBox.onClickOk}
                            cancelTxt="Отмена"
                            onClickCancel={this.closeMsgBox}
                        /> :
                        null
                }

                <ContainerHead onClick={() => this.props.history.goBack()} name="Выбор тарифа" />

                {
                    this.tariffList.map(tariff =>
                        <Tariff
                            key={tariff.id}
                            tariff={tariff}
                            myTariff={this.myTariff}
                            onClick={tariff.id === this.myTariff.id ? this.prolongateTariff : this.chooseTariff}
                        />
                    )
                }
                
            </div>
        )
    }

    componentDidMount() {
        AppInit.fetchTariffList()
            .then(() => {
                this.tariffList = AppInit.getTariffList()
                return AppInit.fetchMyTariff()
            })
            .then(() => {
                this.myTariff = AppInit.getMyTariff()
                this.setState({ isWait: false })
            })
            .catch((err) => {
                console.log(err.message)
                this.setState({ isWait: false })
            })
    }

    chooseTariff(id) {
        let newTariff = this.tariffList.filter(tariff => tariff.id === id)
        const msgBox = {
            show: true,
            msgTxt: `Перейти на новый тариф ${newTariff[0].name}?`,
            onClickOk: () => this.applyTariff(id)
        }
        this.setState({msgBox})
    }

    applyTariff(id) {
        this.setState({
            isWait: true,
            msgBox: {
                show: false,
                msgTxt: '',
                onClickOk: null
            }
        });
        AppInit.updateTariff(id)
            .then(needPayment => {
                const history = needPayment ? '/payment' : '/settings';
                this.props.history.push(history);
            })
            .catch(error => {
                this.setState({ isWait: false });
                this.msgRef.current.show('error', error);
            })
    }

    closeMsgBox() {
        const msgBox = {
            show: false,
            msgTxt: '',
            onClickOk: null
        }
        this.setState({msgBox})
    }

    prolongateTariff(monthCount) {
        const msgBox = {
            show: true,
            msgTxt: `Продлить текущий тариф на ${AppInit.getProlongatePeriodStr(monthCount)}?`,
            onClickOk: () => this.applyProlongateTariff(monthCount)
        }
        this.setState({msgBox})
    }

    applyProlongateTariff(monthCount) {
        this.setState({
            isWait: true,
            msgBox: {
                show: false,
                msgTxt: '',
                onClickOk: null
            }
        });
        AppInit.prolongateTariff(monthCount)
            .then(needPayment => {
                const history = needPayment ? '/payment' : '/settings';
                this.props.history.push(history);
            })
            .catch(error => {
                this.setState({ isWait: false });
                this.msgRef.current.show('error', error);
            })
    }
}

export default TariffList