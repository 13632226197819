import React, { Component, createRef } from 'react'
import './FBSearch.css'
import { Feedback } from './Feedback/Feedback'
import ScoreView from './ScoreView/ScoreView'
import Preloader from '../../components/UI/Preloader/Preloader'
import CognitoHandle from '../../CognitoAuth/CognitoHandle'
import NavBar from '../../NavBar/NavBar'
import ContainerHead from '../../components/ContainerHead/ContainerHead'
import Msg from '../../components/UI/Msg/Msg'

class FBSearch extends Component {
    constructor(props) {
        super(props)

        this.search = this.search.bind(this)
        this.prepareFeedbacks = this.prepareFeedbacks.bind(this)
        this.searchErrorHandle = this.searchErrorHandle.bind(this)
        this.msgRef = createRef()

        this.state = {
            scores: {},
            feedbackList: [],
            isWait: false,
            searchAttempt: false,
        }
    }

    render() {
        return (
            <div className="FBSearch">
                <Msg ref={this.msgRef} />

                {this.state.isWait && <Preloader />}

                <ContainerHead linkTo="/" name="Результаты поиска" />

                {this.state.feedbackList.length === 0 &&
                this.state.searchAttempt ? (
                    <div className="no-result">Отзывы не найдены</div>
                ) : null}

                {this.state.feedbackList.length > 0 ? (
                    <ScoreView scores={this.state.scores} />
                ) : null}

                {this.state.feedbackList.map((item, i) => {
                    return <Feedback key={i} feedback={item} />
                })}

                <NavBar />
            </div>
        )
    }

    componentDidMount() {
        let requestData = ''

        const searchStr = decodeURIComponent(this.props.location.search)
            .slice(1)
            .split('=')

        if (searchStr[0] === 'guestFio') {
            const parenthesesPos = searchStr[1].indexOf('(')

            requestData = {
                [searchStr[0]]: searchStr[1].slice(0, parenthesesPos),
                birthday: searchStr[1].slice(parenthesesPos + 1, -1),
            }
        } else {
            requestData = {
                [searchStr[0]]: searchStr[1],
            }
        }

        this.search(requestData)
    }

    search(requestData) {
        this.setState({ isWait: true })
        let status

        fetch('https://api.alodging.by/v1/api/mob/feedback/search', {
            method: 'POST',
            headers: {
                Accept: '*/*',
                'Content-Type': 'application/json',
                Authorization: CognitoHandle.tokens.idToken,
                Host: 'api.alodging.by',
            },
            body: JSON.stringify(requestData),
        })
            .then((response) => {
                // может вернуться ошибка 402 с data = {message: "Время действия тарифа истекло"}
                status = response.status
                return response.json()
            })
            .then((data) => {
                if (status === 200) {
                    this.prepareFeedbacks(data)
                } else {
                    this.setState({ isWait: false })
                    this.msgRef.current.show('error', data.message, () => {
                        switch (status) {
                            case 400:
                                // ошибка поиска (например нет даты при поиске по фамилии)
                                this.props.history.push('/')
                                break
                            case 402:
                                // 402 - отправляем в личный кабинет
                                this.props.history.push('/settings')
                                break
                            default:
                                this.props.history.push('/login')
                        }
                    })
                }
            })
            .catch((error) => this.searchErrorHandle(error, requestData))
    }

    prepareFeedbacks(data) {
        let feedbackList = []

        data.content.forEach((item) => {
            let feedback = {}
            feedback.authorName = item.authorName
            feedback.rating = item.rating
            feedback.city = item.city
            feedback.categories = item.categories
            feedback.addDate = item.addDate
            feedback.feedback = item.feedback
            feedbackList.push(feedback)
        })

        this.setState({
            scores: data.scores,
            feedbackList,
            isWait: false,
            searchAttempt: true,
        })
    }

    searchErrorHandle(error, requestData) {
        let self = this

        console.log('searchErrorHandle: ' + error)

        CognitoHandle.refreshSession(function (err) {
            if (err) {
                console.log('refreshSession: ' + error)
                self.props.history.push('/login')
            } else {
                self.search(requestData)
            }
        })
    }
}

export default FBSearch
