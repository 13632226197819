import React from 'react';
import './ScoreView.css';
import starWhite from './star-white.svg';

function ScoreView(props) {
    const prescription = {
        "NOTRECOMMENDED": "Не рекомендую",
        "BEARABLE": "Терпимо",
        "RECOMMENDED": "Рекомендую"
    }

    let classList = ['ScoreView'];

    if (!props.scores.recom || props.scores.recom === 'BEARABLE')
        classList.push('bearable');

        if (props.scores.recom === 'RECOMMENDED')
        classList.push('recommended');

    return(
        <div className={classList.join(' ')}>
            <div className="rating">
                {props.scores.score ?
                    <div className="score">
                        <img src={starWhite} width="15" alt=""></img>
                        {props.scores.score}
                    </div> : 
                    null
                }
                <div className="prescription">
                    {props.scores.recom ? prescription[props.scores.recom] : 'Нет оценок'}
                </div>
            </div>
            <div className="rating">
                <div className="count">
                    <span>{props.scores.totalReviews}</span>
                    <br/>
                    <small>Оценок</small>
                </div>
                <div className="count">
                    <span>{props.scores.noShow}</span>
                    <br/>
                    <small>Незаезды</small>
                </div>
                <div className="count">
                    <span>{props.scores.reject}</span>
                    <br/>
                    <small>Отказано</small>
                </div>
            </div>
        </div>
    );
}

export default ScoreView;