import React from 'react'
import ContainerHead from '../ContainerHead/ContainerHead'

import './AboutPayment.css'

export default (props) => {

    return (
        <div className="AboutPayment">
            <ContainerHead onClick={() => { props.history.goBack() }} name="Онлайн оплата услуг" />
            <div className="description">
                <p>
                    Услуги доступа к базе ALodging оплачиваются банковской платёжной картой через систему оплаты WebPay.
                </p>
                <p>
                    Безопасный сервер WEBPAY устанавливает шифрованное соединение по защищенному протоколу TLS и конфиденциально принимает от клиента данные его платёжной карты (номер карты, имя держателя, дату окончания действия, и контрольный номер банковской карточке CVC/CVC2).
                </p>
                <div>
                    <h3>
                        Описание процедуры оплаты услуг доступа к базе ALodging
                    </h3>
                    <ul>
                        <li>
                            Оплата счета осуществляется в личном кабинете. Чтобы попасть в личный кабинет необходима авторизация.
                        </li>
                        <li>
                            Счет для оплаты система ALodging формирует автоматически после каких-либо действий по выбору или продлению определенного тарифного плана (подписки).
                        </li>
                        <li>
                            Когда счет выставлен у вас появиться возможность оплатить его или отменить.
                        </li>
                        <li>
                            После нажатия кнопки "Оплатить" вы попадете на сайт партнера webpay.by для осуществления оплаты счета.
                        </li>
                        <li>
                            После успешной оплаты необходимо нажать "Вернуться на сайт". Ваши изменения (смена или продление срока действия тарифного плана) вступят в силу.
                        </li>
                    </ul>
                </div>
                <p>
                    В случае, если вам не оказана услуга, или по каким то причинам вы хотите отменить оплату, необходимо обратиться по телефону +375 (33) 371-11-10.
                </p>
                <div>
                    <h3>
                        Обращаем ваше внимание
                    </h3>
                    <ul>
                        <li>После совершения оплаты с использованием банковской карты необходимо сохранять полученные карт-чеки (подтверждения об оплате) для сверки с выпиской из карт-счёта (с целью подтверждения совершённых операций в случае возникновения спорных ситуаций).</li>
                        <li>Отменить счет возможно до его оплаты (кнопка "Отменить").</li>
                        <li>При оплате банковской платежной картой возврат денежных средств осуществляется на карточку, с которой была произведена оплата.</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}