import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './SetRating.css'

export default React.forwardRef((props, ref) => {
    const values = [1, 2, 3, 4, 5]
    const valuesStr = [
        'Нет оценки',
        'Не рекомендую',
        'Не рекомендую',
        'Терпимо',
        'Рекомендую',
        'Рекомендую'
    ]

    let cls = ['SetRating']

    if (props.alert) {
        cls.push('alert')
    }

    return (
        <div className={cls.join(' ')} ref={ref}>
            <div className="value-str">
                <span>Оцените гостя</span>
                <strong>{valuesStr[props.value]}</strong>
            </div>
            <div className="stars">
                {
                    values.map(item => getIcon(item, props.value, props.onClick))
                }
            </div>
        </div>
    )
})

function getIcon(count, value, onClick) {
    const color = count <= value ? '#42a5f6' : 'gainsboro'
    const icon = count <= value ? 'star' : ['far', 'star']

    return <FontAwesomeIcon key={count} icon={icon} color={color} size="2x" onClick={() => onClick(count)} />
}