import React, { Component, createRef } from 'react';

import AppInit from '../../AppInit';

import Preloader from '../../components/UI/Preloader/Preloader';
import PhoneView from './PhoneView/PhoneView';
import { PhoneCard, PhoneDel } from './PhoneCard/PhoneCard';
import Msg from '../../components/UI/Msg/Msg';
import ContainerHead from '../../components/ContainerHead/ContainerHead'

import './PhoneList.css';

class PhoneList extends Component {
    constructor(props) {
        super(props);

        this.addPhone = this.addPhone.bind(this);
        this.onEditOk = this.onEditOk.bind(this);
        this.onEditCancel = this.onEditCancel.bind(this);
        this.onDelOk = this.onDelOk.bind(this);
        this.save = this.save.bind(this);
        this.msgRef = createRef();

        this.phones = AppInit.getPhones();
        this.isAddAction = false;
        this.isDelAction = false;

        this.state = {
            isWait: false,
            phoneToEdit: -1
        }
    }

    render() {
        return (
            <div className="PhoneList">
                {this.state.isWait ? <Preloader /> : null}

                <Msg ref={this.msgRef} />

                <ContainerHead linkTo="/settings" name="Список телефонов" />

                <div
                    className="add-phone"
                    onClick={this.addPhone}
                >
                    Добавить телефон
                </div>

                {
                    this.phones.map((item, i) => {
                        if (i === this.state.phoneToEdit) {
                            if (this.isDelAction) {
                                return (
                                    <PhoneDel
                                        key={i}
                                        number={item.number}
                                        onDelOk={this.onDelOk}
                                        onDelCancel={this.onEditCancel}
                                    />
                                );
                            } else {
                                let phone = { ...item };
                                return (
                                    <PhoneCard
                                        key={i}
                                        phone={phone}
                                        onEditOk={this.onEditOk}
                                        onEditCancel={this.onEditCancel}
                                    />
                                );
                            }
                        }

                        return (
                            <PhoneView
                                key={i}
                                phone={item}
                                onEdit={() => this.setState({ phoneToEdit: i })}
                                onDel={() => {
                                    this.isDelAction = true;
                                    this.setState({ phoneToEdit: i });
                                }}
                            />
                        );
                    })
                }
                <div style={{ textAlign: 'center' }}>
                    <button className="save"
                        onClick={this.save}
                    >
                        Сохранить
                    </button>
                </div>
            </div>
        )
    }

    addPhone() {
        let phone = {
            number: '+375',
            name: '',
            admin: false,
            serviceEnabled: true
        };

        this.phones.push(phone);
        this.isAddAction = true;
        this.setState({ phoneToEdit: this.phones.length - 1 });
    }

    onEditOk(phone) {
        this.isAddAction = false;
        this.phones[this.state.phoneToEdit] = phone;
        this.setState({ phoneToEdit: -1 });
    }

    onEditCancel() {
        if (this.isAddAction) {
            this.phones.pop();
        }
        this.isAddAction = false;
        this.isDelAction = false;
        this.setState({ phoneToEdit: -1 });
    }

    onDelOk() {
        this.phones.splice(this.state.phoneToEdit, 1);
        this.isDelAction = false;
        this.setState({ phoneToEdit: -1 })
    }

    save() {
        this.setState({ isWait: true });
        AppInit.savePhones(this.phones)
            .then(needPayment => {
                const history = needPayment ? '/payment' : '/settings';
                this.props.history.push(history);
            })
            .catch(error => {
                this.phones = AppInit.getPhones()
                this.setState({ isWait: false });
                this.msgRef.current.show('error', error);
            })
    }
}

export default PhoneList;
