import React, { Component, createRef } from 'react';

import AppInit from '../../AppInit';

import ContainerHead from '../../components/ContainerHead/ContainerHead'
import Preloader from '../../components/UI/Preloader/Preloader';
import Msg from '../../components/UI/Msg/Msg';
import PaymentLogo from '../../components/UI/PaymentLogo/PaymentLogo'

import './Payment.css';

class Payment extends Component {
    constructor(props) {
        super(props);

        this.preparePayment = this.preparePayment.bind(this);
        this.denyPayment = this.denyPayment.bind(this);
        this.msgRef = createRef();

        this.state = {
            isWait: true,
        };
    }

    render() {
        return (
            <div className="Payment">
                <div>
                    {this.state.isWait ? <Preloader /> : null}
    
                    <Msg ref={this.msgRef} />
    
                    <ContainerHead linkTo="/settings" name="Счет" />
    
                    <div>Сумма: {AppInit.getPendingTransactionAmount()} byn</div>
                    <div>{AppInit.getPendingTransactionDescription()}</div>
                    <button
                        className="apply-payment"
                        onClick={() => this.preparePayment('cc')}
                        disabled={AppInit.getPendingTransactionId() === -1}>
                        Оплатить банковской картой VISA/Mastercard
                        </button>
                    <button
                        className="apply-payment"
                        onClick={() => this.preparePayment('erip')}
                        disabled={AppInit.getPendingTransactionId() === -1}>
                        Оплатить через ЕРИП
                        </button>
                    <button
                        className="deny-payment"
                        onClick={this.denyPayment}
                        disabled={AppInit.getPendingTransactionId() === -1}>
                        Отменить
                        </button>
                    <div className="descr">
                        <p>
                            Онлайн оплата банковской картой VISA/Mastercard осуществляется через систему оплаты WebPay.
                        </p>
                        <p>
                            Безопасный сервер WEBPAY устанавливает шифрованное соединение по защищенному протоколу TLS и конфиденциально принимает от клиента данные его платёжной карты (номер карты, имя держателя, дату окончания действия, и контрольный номер банковской карточке CVC/CVC2).
                        </p>
                        <p>
                            После нажатия кнопки "Оплатить" вы попадете на сайт партнера webpay.by для осуществления оплаты счета.
                            После успешной оплаты необходимо нажать "Вернуться на сайт".
                            В случае, если вам не оказана услуга в соответствии с выставленным счетом, или по каким то причинам вы хотите отменить оплату, необходимо обратиться по телефону +375 (33) 371-11-10.
                        </p>
                        <div>
                            <h3>
                                Обращаем ваше внимание
                            </h3>
                            <ul>
                                <li>После совершения оплаты с использованием банковской карты необходимо сохранять полученные карт-чеки (подтверждения об оплате) для сверки с выпиской из карт-счёта (с целью подтверждения совершённых операций в случае возникновения спорных ситуаций).</li>
                                <li>Отменить заказ возможно до его оплаты (кнопка "Отменить").</li>
                                <li>При оплате банковской платежной картой возврат денежных средств осуществляется на карточку, с которой была произведена оплата.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <PaymentLogo />
            </div>
        );
    }

    componentDidMount() {
        AppInit.checkPendingTransaction()
            .then(() =>
                this.setState({
                    isWait: false
                })
            )
            .catch(() =>
                this.setState({
                    isWait: false
                }))
    }

    preparePayment(type) {
        const wsb_return_url = 'https://app.alodging.by/settings';
        const wsb_cancel_return_url = 'https://app.alodging.by/settings';

        AppInit.preparePayment(type)
            .then(data => submit(data.redirectUrl, { ...data.fields, wsb_return_url, wsb_cancel_return_url }));

        function buildElement(tagName, props) {
            let element = document.createElement(tagName);
            for (let propName in props) element[propName] = props[propName];
            return element;
        }

        function submit(link, props) {
            let form = buildElement('form', {
                method: 'post',
                action: link
            });

            for (var propName in props)
                form.appendChild(
                    buildElement('input', {
                        type: 'hidden',
                        name: propName,
                        value: props[propName]
                    })
                );

            form.style.display = 'none';
            document.body.appendChild(form);
            form.submit();
        }
    }

    denyPayment() {
        this.setState({ isWait: true });
        AppInit.denyPendingTransaction()
            .then(() => this.props.history.push('/settings'))
            .catch((message) => {
                this.setState({ isWait: false });
                this.msgRef.current.show('error', message);
            });
    }
}

export default Payment;