import React, { Component } from 'react';
import './PhoneCard.css';

export class PhoneCard extends Component {
    constructor(props) {
        super(props);

        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeAdmin = this.onChangeAdmin.bind(this);
        this.onChangeServiceEnabled = this.onChangeServiceEnabled.bind(this);

        this.inputRef = null;

        this.phone = props.phone;
        this.state = {
            okButtonDisable: this.phone.number.length < 7 || this.phone.number.length > 15
        }
    }

    render() {
        return (
            <>
            <div className="PhoneCard-overlay"></div>

            <div className="PhoneCard">
                
                <div className="wrap-input">
                    <label>Телефон</label>
                    <input
                        ref={ref => this.inputRef = ref}
                        placeholder="номер телефона"
                        defaultValue={this.props.phone.number}
                        onChange={this.onChangePhone}
                    />
                </div>
                <div className="wrap-input">
                    <label>Наименование</label>
                    <input
                        placeholder="наименование/описание (не обязательно)"
                        defaultValue={this.props.phone.name}
                        onChange={this.onChangeName}
                    />
                </div>

                <div className="wrap-checkbox">
                    <div>
                        <input
                            type="checkbox"
                            defaultChecked={this.props.phone.admin}
                            onChange={this.onChangeAdmin}
                        />
                        <label>администратор</label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            defaultChecked={this.props.phone.serviceEnabled}
                            onChange={this.onChangeServiceEnabled}
                        />
                        <label>сервис доступен</label>
                    </div>
                </div>

                <div className="wrap-buttons">
                    <button
                        className="OK"
                        disabled={this.state.okButtonDisable}
                        onClick={() => this.props.onEditOk(this.phone)}
                    >
                        ОК
                    </button>
                    <button
                        className="cancel"
                        onClick={this.props.onEditCancel}
                    >
                        Отмена
                    </button>
                </div>
            </div>
            </>
        )
    }

    onChangePhone(e) {
        const prevState = this.state.okButtonDisable;
        let newState = this.state.okButtonDisable;

        e.target.value = e.target.value.split('')
                    .filter(char => char !== ' ' && (char === '+' || Number.isInteger(-char)))
                    .join('');

        this.phone.number = e.target.value;
        newState = e.target.value.length < 7 || e.target.value.length > 15
        if (prevState !== newState) {
            this.setState({
                okButtonDisable: newState
            })
        } 
    }

    onChangeName(e) {
        this.phone.name = e.target.value;
    }

    onChangeAdmin(e) {
        this.phone.admin = e.target.checked;
    }

    onChangeServiceEnabled(e) {
        this.phone.serviceEnabled = e.target.checked;
    }

    componentDidMount() {
        if (this.inputRef) {
            this.inputRef.focus();
        }
    }
}

export function PhoneDel(props) {
    return(
        <>
        <div className="PhoneCard-overlay"></div>

        <div className="PhoneCard">
            <div className="del-warning">Удалить телефон {props.number}?</div>
            <div className="wrap-buttons">
                    <button
                        className="OK"
                        onClick={props.onDelOk}
                    >
                        Да
                    </button>
                    <button
                        className="cancel"
                        onClick={props.onDelCancel}
                    >
                        Нет
                    </button>
                </div>
        </div>
        </>
    );
}