import React, { Component, createRef } from 'react'

import NavBar from '../../NavBar/NavBar'

import AppInit  from '../../AppInit'

import TextField from '../../components/UI/TextField/TextField'
import SelectItem from '../../components/SelectItem/SelectItem'
import Msg from '../../components/UI/Msg/Msg'

import './FBSearchForm.css'

class FBSearchForm extends Component {

    constructor(props) {
        super(props)

        this.callSearch = this.callSearch.bind(this)
        this.inputHandle = this.inputHandle.bind(this)
        this.checkOrgList = this.checkOrgList.bind(this)
        this.selectOrg = this.selectOrg.bind(this)
        this.checkFioList = this.checkFioList.bind(this)
        this.selectGuest = this.selectGuest.bind(this)

        this.inputOrgRef = createRef()
        this.inputFioRef = createRef()
        this.msgRef = createRef()
        this.searchRequest = null

        this.state = {
            phoneNumber: '',
            guestFio: '',
            fioList: [],
            organizationName: '',
            orgList: [],
            unp: '',
            buttonDisable: true
        }
    }

    inputHandle(e) {
        const name = e.target.name
        let value = e.target.value
        let buttonDisable = this.state.buttonDisable

        switch (name) {
            case 'phoneNumber':
                value = value.split('')
                    .filter(char => char !== ' ' && (char === '+' || Number.isInteger(-char)))
                    .join('')
                buttonDisable = value.length < 7 || value.length > 15
                break
            case 'guestFio':
                buttonDisable = true
                if (value.length > 2) 
                    this.checkFioList(value)
                else
                    this.setState({fioList:[]})
                break
            case 'organizationName':
                buttonDisable = value.length < 4
                this.checkOrgList(value);
                break
            case 'unp':
                value = value.split('')
                    .filter(char => char !== ' ' && Number.isInteger(-char))
                    .join('');
                buttonDisable = e.target.value.length !== 9
                break
            default:
        }

        this.setState({
            [name]: value,
            buttonDisable
        })

        this.searchRequest = {
            [name]: value
        }
    }

    render() {
        return (
            <>
                <Msg ref={this.msgRef} />
                <div className="FBSearchForm">
                    <h1>Проверить гостя</h1>
                    <p>Внесите информацию, которой располагаете, чтобы проверить есть ли на вашего гостя отзыв</p>
                    <TextField
                        label="Телефон"
                        name="phoneNumber"
                        placeholder="Формат +375291112233"
                        value={this.state.phoneNumber}
                        onChange={this.inputHandle}
                    />
                    <TextField
                        ref={this.inputFioRef}
                        label="ФИО"
                        name="guestFio"
                        value={this.state.guestFio}
                        onChange={this.inputHandle}
                    />

                    {
                        this.state.fioList.length
                            ? <SelectItem
                                items={this.state.fioList}
                                onClick={this.selectGuest}
                                parentElement={this.inputFioRef.current}
                                overlayOnClick={() => this.setState({ fioList: [] })}
                            />
                            : null
                    }

                    <TextField
                        ref={this.inputOrgRef}
                        label="Название организации"
                        name="organizationName"
                        value={this.state.organizationName}
                        onChange={this.inputHandle}
                    />

                    {
                        this.state.orgList.length
                            ? <SelectItem
                                items={this.state.orgList}
                                onClick={this.selectOrg}
                                parentElement={this.inputOrgRef.current}
                                overlayOnClick={() => this.setState({ orgList: [] })}
                            />
                            : null
                    }

                    <TextField
                        label="УНП"
                        name="unp"
                        value={this.state.unp}
                        onChange={this.inputHandle}
                    />
                    <button onClick={this.callSearch} disabled={this.state.buttonDisable}>ПРОВЕРИТЬ</button>
                </div>
                <NavBar />
            </>
        )
    }

    componentDidMount() {
        // Надо получить категории, они нужны в результатах поиска
        // заодно проверим валидность сессии
        AppInit.fetchCategories()
            .then(() => console.log('categories fetched'))
            .catch(error => {
                this.msgRef.current.show('error', error.message, () => {
                    this.props.history.push('/login')
                })
            })
    }

    callSearch() {
        const searchStr = Object.entries(this.searchRequest)[0].join('=')
        this.props.history.push('/search?' + searchStr)
    }

    checkOrgList(name) {
        const request = {
            text: name
        };

        fetch('https://api.alodging.by/v1/api/mob/autocomplete/org', {
            method: 'POST',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        }).then(response => response.json())
            .then(data => this.showOrgList(data, name))
            .catch(error => console.log(error));
    }

    showOrgList(data, name) {
        const orgList = data.slice(0, 5)
            .filter(item => item !== name)
            .map((item, i) => {
                return { name: item, id: i }
            })
        this.setState({ orgList });
    }

    selectOrg(item) {

        const organizationName = item.name
        const orgList = []

        this.searchRequest = { organizationName }

        this.setState({
            organizationName,
            orgList,
            buttonDisable: false
        })
    }

    checkFioList(name) {
        const request = {
            text: name
        };

        fetch('https://api.alodging.by/v1/api/mob/autocomplete/fio', {
            method: 'POST',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        }).then(response => response.json())
            .then(data => this.showFioList(data, name))
            .catch(error => console.log(error));
    }

    showFioList(data, name) {
        const fioList = data.slice(0, 5)
            .filter(item => item !== name)
            .map((item, i) => {
                const itemValues = item.split('|')
                const newItemView = `${itemValues[0]} (${itemValues[1]})`
                return { name: newItemView, id: i }
            })
        this.setState({ fioList });
    } 

    selectGuest(item) {
        const guestFio = item.name
        const fioList = []

        this.searchRequest = { guestFio }

        this.setState({
            guestFio,
            fioList,
            buttonDisable: false
        })
    }
}

export default FBSearchForm;