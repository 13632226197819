import React, { Component } from 'react'
import './Tariff.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const tariffFeatures = [
    {
        lab: 'VIEW_INCOMING',
        name: 'Проверка входящих звонков'
    },
    {
        lab: 'VIEW_OUTGOING',
        name: 'Проверка исходящих звонков'
    },
    {
        lab: 'VIEW_VISABILITY_CATEGORIES',
        name: 'Детализация рейтинга'
    },
    {
        lab: 'VIEW_VISABILITY_ACTION_SEARCH',
        name: 'Быстрый просмотр отзывов по номеру телефона после разговора/звонка'
    },
    {
        lab: 'VIEW_VISABILITY_ACTION_ADD',
        name: 'Быстрое добавление отзывов с автозаполнением'
    },
    {
        lab: 'SETTING_CONTACT_SEARCHING',
        name: 'Опция «Не отображать информацию с рейтингом, если номер в контактной книге»'
    },
    {
        lab: 'SETTING_VIEW_AUTOCLOSE',
        name: 'Опция «Автозакрытие информации с рейтингом при входящем звонке»'
    }
]

export default ({ tariff, myTariff, onClick }) => {
    return (
        <div className="Tariff">

            <h3>
                {tariff.name}
                {
                    tariff.id === myTariff.id
                        ? <span>(ваш тариф)</span>
                        : null
                }
            </h3>

            <Feature
                name="Доступ к базе отзывов"
                value={<FontAwesomeIcon icon="check" color="green" size="lg" />}
            />

            <Feature
                name="Количество телефонных номеров"
                value={tariff.details.prepaidSimNumber}
            />

            <Feature
                name="Возможность добавлять свои отзывы"
                value={<FontAwesomeIcon icon="check" color="green" size="lg" />}
            />

            <Feature
                name="Поиск отзывов по номеру телефона"
                value={<FontAwesomeIcon icon="check" color="green" size="lg" />}
            />

            <Feature
                name="Поиск отзывов по УНП и названию организации"
                value={<FontAwesomeIcon icon="check" color="green" size="lg" />}
            />

            <div className="android-features">
                <div>Возможности мобильного приложения на Android</div>
                {
                    tariffFeatures.map((feature, i) =>
                        <Feature
                            key={i}
                            name={feature.name}
                            value={getIcon(feature.lab)}
                        />
                    )
                }
            </div>

            <Feature
                name="Стоимость подключения дополнительного номера"
                value={`${tariff.details.extraSimRate} byn`}
            />

            {
                tariff.id === myTariff.id
                    ? null
                    : <>
                        <Feature
                            name="Стоимость подписки на 12 месяцев (скидка 30%)"
                            value={`${tariff.details.yearRate} byn`}
                        />
                        <Feature
                            name="Стоимость подписки на 3 месяца (скидка 10%)"
                            value={`${tariff.details.month3Rate} byn`}
                        />
                        <Feature
                            name="Стоимость подписки на месяц"
                            value={`${tariff.details.monthRate} byn`}
                        />
                    </>
            }

            {getButton()}
        </div>
    )

    function getIcon(lab) {
        const icon = tariff.details.features.includes(lab) ? 'check' : 'times'
        const color = tariff.details.features.includes(lab) ? 'green' : 'tomato'
        return <FontAwesomeIcon icon={icon} color={color} size="lg" />
    }

    function getButton() {
        if (!myTariff.id) {
            return null
        }

        const expireDate = <div className="expire-date">
            Срок действия до <strong>{myTariff.expireDate}</strong>
        </div>

        if (tariff.id === myTariff.id) {
            if (tariff.details.prolongable) {
                return (
                    <>
                        {expireDate}
                        <Prolongate onClick={onClick} tariff={tariff} />
                    </>)
            } else {
                return expireDate
            }
        }

        if (tariff.id !== myTariff.id)
            return <button onClick={() => onClick(tariff.id)}>Выбрать</button>
    }
}

function Feature(props) {
    return (
        <div className="feature">
            <div className="feature-name">{props.name}</div>
            <div className="feature-value">{props.value}</div>
        </div>
    )
}

class Prolongate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            monthCount: 12
        }
    }

    render() {
        return (
            <div className="Prolongate">
                <p>Период</p>
                <div className={this.state.monthCount === 12 ? 'selected' : ''}>
                    <div>
                        <FontAwesomeIcon
                            icon={this.state.monthCount === 12 ? ['far', 'dot-circle'] : ['far', 'circle']}
                            onClick={() => this.setState({ monthCount: 12 })}
                        />
                        <span>12 месяцев (скидка 30%)</span>
                    </div>
                    <span>{this.props.tariff.details.yearRate} byn</span>
                </div>
                <div className={this.state.monthCount === 3 ? 'selected' : ''}>
                    <div>
                        <FontAwesomeIcon
                            icon={this.state.monthCount === 3 ? ['far', 'dot-circle'] : ['far', 'circle']}
                            onClick={() => this.setState({ monthCount: 3 })}
                        />
                        <span>3 месяца (скидка 10%)</span>
                    </div>
                    <span>{this.props.tariff.details.month3Rate} byn</span>
                </div>
                <div className={this.state.monthCount === 1 ? 'selected' : ''}>
                    <div>
                        <FontAwesomeIcon
                            icon={this.state.monthCount === 1 ? ['far', 'dot-circle'] : ['far', 'circle']}
                            onClick={() => this.setState({ monthCount: 1 })}
                        />
                        <span>1 месяц</span>
                    </div>
                    <span>{this.props.tariff.details.monthRate} byn</span>
                </div>
                <button onClick={() => this.props.onClick(this.state.monthCount)}>Продлить</button>
            </div>
        )
    }
}