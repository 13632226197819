import React, { Component } from 'react'
import './Msg.css'

class Msg extends Component {
    constructor(props) {
        super(props)

        this.onClickHandle = this.onClickHandle.bind(this)

        this.timeout = null

        this.state = {
            hide: true,
            type: 'success',
            txt: '',
            cb: null
        }
    }

    render() {
        let classList = ['Msg']
        classList.push(this.state.type)
        this.state.hide && classList.push('hide')

        return (
            <div className={classList.join(' ')} onClick={this.onClickHandle}>
                {this.state.txt}
            </div>
        )
    }

    show(type, txt, cb) {
        this.setState({ hide: false, type, txt, cb })
        this.timeout = setTimeout(this.onClickHandle, 7000)
    }

    onClickHandle() {
        clearTimeout(this.timeout)
        this.setState({hide: true})
    }

    componentDidUpdate() {
        this.state.hide && this.state.cb && this.state.cb()
    }
}

export default Msg;