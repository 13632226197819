import {
    AuthenticationDetails,
    CognitoUserPool,
    CognitoUser,
    CognitoRefreshToken,
    CognitoUserAttribute
} from 'amazon-cognito-identity-js';
import authConfig from './authConfig';

class CognitoHandle {

    static tokens = {
        accessToken: '',
        idToken: '',
        refreshToken: ''
    };

    static authPhoneNumber = null

    static cognitoUser = null;

    static signUpAttempt = false;

    static initiateAuth(phoneNumber, cb) {
        let self = this;
        
        // эти данные не спользуются на самом деле
        // но authenticationDetails необходим в initiateAuth
        const authenticationData = {
            Username: 'username',
            Password: 'password'
        };

        const authenticationDetails = new AuthenticationDetails(authenticationData);

        const poolData = {
            UserPoolId: authConfig.UserPoolId,
            ClientId: authConfig.ClientId
        };

        const userPool = new CognitoUserPool(poolData);

        const userData = {
            Username: phoneNumber,
            Pool: userPool
        };

        this.cognitoUser = new CognitoUser(userData);
        this.cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
        this.cognitoUser.initiateAuth(authenticationDetails, {
            onSuccess: function (result) {
                // такого не будет
            },

            customChallenge: function (challengeParameters) {
                cb(null);
            },

            onFailure: function (err) {
                console.log(err)
                // if (self.signUpAttempt) {
                //     self.signUpAttempt = false;
                //     cb(err);
                // } else {
                //     self.signUp(phoneNumber, (err) => {
                //         if (err) {
                //             cb(err);
                //         } else {
                //             self.signUpAttempt = true;
                //             self.initiateAuth(phoneNumber, cb);
                //         }
                //     });
                // }

                // от signUpAttempt вроже можно избавиться по коду ошибки
                if (err.code === 'UserNotFoundException') {
                    self.signUp(phoneNumber, err => {
                        if (err) {
                            console.log(err)
                            cb(err)
                        } else {
                            self.initiateAuth(phoneNumber, cb)
                        }
                    })
                } else {
                    cb(err)
                }
            }
        });
    }

    static signUp(phoneNumber, cb) {

        const poolData = {
            UserPoolId: authConfig.UserPoolId,
            ClientId: authConfig.ClientId
        };

        let userPool = new CognitoUserPool(poolData);

        let attributeList = [];

        const dataPhoneNumber = {
            Name: 'phone_number',
            Value: phoneNumber,
        };

        let attributePhoneNumber = new CognitoUserAttribute(
            dataPhoneNumber
        );

        attributeList.push(attributePhoneNumber);

        // пароль фиктивный, не используется в дальнейшем, но нужен для регистрации
        userPool.signUp(phoneNumber, '1234567890', attributeList, null, function (
            err,
            result
        ) {
            if (err) {
                cb(err);
            } else {
                cb(null, result);
            }
        });

    }

    static updateTokens(data) {
        let self = this;

        this.tokens.accessToken = data.getAccessToken().getJwtToken();
        this.tokens.idToken = data.getIdToken().getJwtToken();
        this.tokens.refreshToken = data.getRefreshToken().getToken();

        // месяц на cookie
        document.cookie = 'refreshToken=' + this.tokens.refreshToken + '; max-age=2592000';
        this.cognitoUser.getUserData((err, data) => {
            if (!err) {
                data.UserAttributes.forEach(attribut => {
                    if (attribut.Name === 'phone_number')
                        self.authPhoneNumber = attribut.Value;
                });
            }
        });
    }

    static sendCustomChallengeAnswer(challengeAnswer, cb) {
        let self = this;

        this.cognitoUser.sendCustomChallengeAnswer(challengeAnswer, {
            onSuccess: function (data) {
                self.updateTokens(data);
                cb(null);
            },

            customChallenge: function (err) {
                cb(err);
            },

            onFailure: function (err) {
                cb(err);
            }
        });
    }

    static refreshSession(cb) {
        let self = this;

        const refreshTokenValue = this.tokens.refreshToken.length > 0 ? this.tokens.refreshToken : getCookie('refreshToken');
        const refreshToken = new CognitoRefreshToken({ RefreshToken: refreshTokenValue });

        const poolData = {
            UserPoolId: authConfig.UserPoolId,
            ClientId: authConfig.ClientId
        }

        const userPool = new CognitoUserPool(poolData);

        const userData = {
            Username: '',
            Pool: userPool
        }

        this.cognitoUser = new CognitoUser(userData);

        this.cognitoUser.refreshSession(refreshToken, (err, data) => {
            if (err) {
                cb(err);
            } else {
                self.updateTokens(data);
                cb(null);
            }
        });
    }
}

function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        '(?:^|; )' + name.replace(/([.$?*|{}()[]\/+^])/g, '\\$1') + '=([^;]*)'
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export default CognitoHandle;