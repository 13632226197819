import React from 'react'
import VisitType from './VisitType/VisitType'

import './ResultVisitType.css'

export default props => {
    return (
        <div className="ResultVisitType">
            <p>Ваш гость:</p>
            <VisitType type="DENIED" value={props.value} onClick={props.onClick}/>
            <VisitType type="NOT_COME" value={props.value} onClick={props.onClick}/>
            <VisitType type="NOT_COME_WITH_REASON" value={props.value} onClick={props.onClick}/>
            <VisitType type="LIVED" value={props.value} onClick={props.onClick}/>
        </div>
    )
}