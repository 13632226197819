import React from 'react'
import { Link } from 'react-router-dom'
import './ContainerHead.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default (props) => {
    if (props.onClick) {
        return (
            <div className="ContainerHead">
                <Link onClick={(e) => {
                    e.preventDefault()
                    props.onClick()
                }} to="/">
                    <FontAwesomeIcon icon="chevron-left" size="2x" color="navy" />
                </Link>
                <h1>{props.name}</h1>
            </div>
        )
    }

    return (
        <div className="ContainerHead">
            <Link to={props.linkTo}>
                <FontAwesomeIcon icon="chevron-left" size="2x" color="navy" />
            </Link>
            <h1>{props.name}</h1>
        </div>
    )
}